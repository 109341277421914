import React, { Component } from "react"
import $ from "jquery"

import { CountryDropdown } from "react-country-region-selector"
import { Container, Row, Col, Button } from "react-bootstrap"
import { Field, formInputData, formValidation } from "reactjs-input-validator"
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import ReCAPTCHA from "react-google-recaptcha"
import { Link } from "gatsby"

export default class Form extends Component {
  state = {
    inputName: "",
    inputEmail: "",
    inputPhone: "",
    inputOrg: "",
    inputCheck: false,
    inputMessage: "",
    isCaptchaValid: false,
    isErrorShown: false,
    isFormValid: false,
  }

  // Handle visitor's interaction with inputs
  handleInput = event => {
    // Test for input and length of the value
    if (event.target.value.length > 0 && event.target.name !== "inputEmail") {
      this.setState({
        [event.target.name]: event.target.value,
      })
    }

    // If input is for email address validate it with regexp
    if (event.target.name === "inputEmail") {
      // eslint-disable-next-line
      const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

      if (reg.test(String(event.target.value).toLowerCase())) {
        this.setState({
          [event.target.name]: event.target.value,
        })
      }
    }
  }
  constructor() {
    super()
    this.state = {
      data: {},
      country: "",
      region: "",
      policy: false,
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handelCheckbox = this.handelCheckbox.bind(this)
  }

  handleChange(event, inputValue, inputName, validationState, isRequired) {
    const value = (event && event.target.value) || inputValue
    const { data } = this.state
    data[inputName] = { value, validation: validationState, isRequired }

    this.setState({
      data,
    })
    // if you want access to your form data
    const formData = formInputData(this.state.data) // eslint-disable-line no-unused-vars
    // tells you if the entire form validation is true or false
    const isFormValid = formValidation(this.state.data) // eslint-disable-line no-unused-vars
  }

  handleSubmit(event) {
    event.preventDefault()
    const formData = formInputData(this.state.data) // eslint-disable-line no-unused-vars

    const isFormValid = formValidation(this.state.data)

    if (isFormValid) {
      $.ajax({
        data: formData,
        type: "POST",
        url:
          "https://backendev.succeedlearn.com/wp-content/plugins/landing-page/contact.php/",
        success: function (data) {
          $("#results").html(data)
        },
        error: function (xhr, status, err) {
          console.error(status, err.toString())
        },
      })
      this.setState({ callAPI: true })
    } else {
      this.setState({ callAPI: true, shouldValidateInputs: !isFormValid })
    }
  }

  handelCheckbox(e) {
    console.log(e.target.checked)
    this.setState({
      [e.target.name]: e.target.checked,
    })
  }

  render() {
    const { country } = this.state
    return (
      <Container>
        <div id="results"></div>
        <form className="example">
          <Row>
            <Col lg={true}>
              <Field
                required
                name="fName"
                placeholder="First Name*"
                onChange={this.handleChange}
                value={this.state.data.fName}
                shouldValidateInputs={this.state.shouldValidateInputs}
                className="form-design"
                requiredErrMsg="Enter Your First Name"
              />
            </Col>
            <Col lg={true}>
              <Field
                validator="isEmail"
                required
                name="email"
                placeholder="Email*"
                onChange={this.handleChange}
                value={this.state.data.email}
                shouldValidateInputs={this.state.shouldValidateInputs}
                className="form-design"
                requiredErrMsg="Enter Your Email Address"
              />
            </Col>
          </Row>
          <Row>
            <Col lg={true}>
              <Field
                matches="^\+(?:[0-9]●?){6,14}[0-9]$"
                required
                name="phone"
                type="text"
                placeholder="Phone*"
                onChange={this.handleChange}
                value={this.state.data.phone}
                shouldValidateInputs={this.state.shouldValidateInputs}
                className="form-design"
                requiredErrMsg="Enter Your Phone Number"
              />
            </Col>

            <Col lg={true} className="country-mobile pb-3">
              <Field
                name="organization"
                placeholder="Organization (Optional)"
                onChange={this.handleChange}
                value={this.state.data.organization}
                className="form-design"
              />
            </Col>
          </Row>
          <Row>
            <Col lg={true}>
              <textarea
                required
                name="message"
                type="text"
                className="form-control messageerror"
                id="textareamessage"
                rows="4"
                placeholder="Message*"
                onChange={this.handleChange}
                value={this.state.data.msg}
                shouldValidateInputs={this.state.shouldValidateInputs}
                requiredErrMsg="Enter your message"
              ></textarea>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col lg={true}>
              <div class="form-check mt-3">
                <input
                  class="form-check-input"
                  name="policy"
                  type="checkbox"
                  value={this.state.policy}
                  onchange={this.handelCheckbox}
                  shouldValidateInputs={this.state.shouldValidateInputs}
                />
                <label class="form-check-label" for="policyarggeyment">
                  Yes, I agree with{" "}
                  <Link to="/privacy-policy"> Privacy Policy </Link>
                </label>
              </div>
            </Col>
            {/* <Col lg={true} className="d-flex justify-content-center mt-sm-2">
              <ReCAPTCHA
                sitekey="6LflAL0ZAAAAAOm-DONrdMnF3ggkfhaFHAjTJf25"
                onChange={this.handelCapche}
              />
            </Col> */}
          </Row>
          <Row>
            <Col md={3}></Col>
            <Col lg={true} className="text-center mt-4">
              <Button
                variant="primary"
                onClick={this.handleSubmit}
                size="md"
                style={{
                  backgroundColor: "#2694C2",
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
              >
                Submit
              </Button>
            </Col>
            <Col md={3}></Col>
          </Row>
        </form>
      </Container>
    )
  }
}
