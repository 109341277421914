import React from "react"
import { Link } from "gatsby"
const Contacthero = props => {
  return (
    <>
      <section className="hero" id="contacthero">
        <div className="container-fluid">
          <div className="row hero-row">
            <div className="col-lg-1"></div>
            <div className="col-lg-5 col-md-12  d-flex justify-content-center flex-column hero-cont ">
              <div className="title">
                <h1 className="heading">{props.title}</h1>
              </div>
              <div className="title-desc  pb-lg-3">
                <div className="img-users">
                  <img
                    src={props.contactimagecircle}
                    alt="users"
                    style={{ width: "50%" }}
                  />
                  <div class="centered">
                    <span className="numbers">
                      {props.contactimagecircletext}
                    </span>
                    <br />
                    <p>{props.contactimagecirclesubtitle}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-12  d-flex justify-content-center flex-column hero-img">
              <picture>
                <source
                  media="(max-width:768px)"
                  srcset={props.contacttabimage}
                />

                <source
                  media="(max-width:480px)"
                  srcset={props.contactmobimage}
                />

                <img
                  src={props.contactimage}
                  alt="hero-landing"
                  className="hero-landingsrc"
                />
              </picture>
            </div>

            <div className="col-lg-1"></div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Contacthero
