import React from "react"
import SEO from "../components/Seo"

import Layout from "../components/layout"
import Contactform from "../components/Form"
import Logoimg from "../components/Clients"
import parser from "react-html-parser"
import { GrPlayFill } from "react-icons/gr"

import TestForm from "../components/Contacttest"
// import Form from "./Form";
import "../css/Contact.css"

import Contacthero from "../components/Contact-hero"

const Contact = ({ data }) => {
  const querydata = data.wordpressPage.acf
  let yoast = data.wordpressPage.yoast_head

  return (
    <>
      <Layout
        logo={data.allWordpressAcfSucceedlearnLogo.edges}
        favicon={data.allWordpressAcfSucceedlearnApple.edges}
      >
        <SEO title={{ yoast }} />

        <Contacthero
          title={querydata.contact_title}
          contactimagecircletext={querydata.contact_image_circle_text}
          contactimagecirclesubtitle={querydata.Contact_Image_circle_subtitle}
          contactimage={
            querydata.contact_image.localFile.childImageSharp.fluid.originalImg
          }
          contacttabimage={
            querydata.contact_tablet_image.localFile.childImageSharp.fluid
              .originalImg
          }
          contactmobimage={
            querydata.contact_mobile_image.localFile.childImageSharp.fluid
              .originalImg
          }
          contactimagecircle={
            querydata.contact_image_circle.localFile.childImageSharp.fluid
              .originalImg
          }
        />

        <section className="clients" id="clients">
          <Logoimg
            clientsrow={data.allWordpressAcfClientsLogos}
            title={data.wordpressPage.acf.clients_title}
          />
          <hr className="client-below" />
        </section>
        <section className="features" id="features">
          <div className="container-fluid bg-img">
            <div className="row">
              <div className="col-lg-1"></div>
              <div className="col-lg-5 contact-feature-end">
                <img
                  src={
                    querydata.contact_feature_image.localFile.childImageSharp
                      .fluid.originalImg
                  }
                  style={{ margin: 0 }}
                />
              </div>
              <div className="col-md-12 col-lg-5 col-sm-12 col-xs-12 details">
                <div className="row icon">
                  <div className="img-icon">
                    <img
                      src={
                        querydata.contact_feature_icon1.localFile
                          .childImageSharp.fluid.originalImg
                      }
                      alt="img1"
                    />
                  </div>
                  <div className="title">
                    <h4>{querydata.contact_feature_title1}</h4>
                  </div>
                </div>

                <div className="row icon">
                  <div className="img-icon">
                    <img
                      src={
                        querydata.contact_feature_icon2.localFile
                          .childImageSharp.fluid.originalImg
                      }
                      alt="img2"
                    />
                  </div>
                  <div className="title">
                    <h4>{querydata.contact_feature_title2}</h4>
                  </div>
                </div>
                <div className="row icon">
                  <div className="img-icon">
                    <img
                      src={
                        querydata.contact_feature_icon3.localFile
                          .childImageSharp.fluid.originalImg
                      }
                      alt="img3"
                    />
                  </div>
                  <div className="title">
                    <h4>{querydata.contact_feature_title3}</h4>
                  </div>
                </div>

                <div className="row para">
                  {parser(querydata.contact_feature_description)}
                </div>
              </div>

              <div className="col-lg-1"></div>
            </div>
          </div>
        </section>

        <section className="schedule" id="schedule">
          <div className="container">
            <h3>SEE IT TO BELIEVE IT </h3>

            <button className="perdemo">Book your personalized demo</button>
          </div>
        </section>

        <TestForm />
        {/* <Contactform /> */}
      </Layout>
    </>
  )
}

export default Contact

export const Contactquery = graphql`
  {
    allWordpressAcfSucceedlearnLogo {
      edges {
        node {
          acf {
            logo_image_ {
              localFile {
                childImageSharp {
                  fluid {
                    originalImg
                  }
                }
              }
            }
          }
        }
      }
    }
    allWordpressAcfSucceedlearnApple {
      edges {
        node {
          acf {
            logo_image_ {
              localFile {
                childImageSharp {
                  fluid {
                    originalImg
                  }
                }
              }
            }
          }
        }
      }
    }

    wordpressPage(title: { eq: "Contact" }) {
      acf {
        contact_title
        clients_title
        contact_image_circle_text
        Contact_Image_circle_subtitle
        contact_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        contact_tablet_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        contact_mobile_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        contact_image_circle {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        contact_feature_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        contact_feature_title1
        contact_feature_title2
        contact_feature_title3
        contact_feature_description

        contact_feature_icon1 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        contact_feature_icon2 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        contact_feature_icon3 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
      }
      yoast_head
    }

    allWordpressAcfClientsLogos {
      edges {
        node {
          acf {
            client_logo_images {
              localFile {
                childImageSharp {
                  fluid {
                    originalImg
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
